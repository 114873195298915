import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Helmet from "react-helmet"
import {
  TransitionState,
  TransitionPortal,
} from "gatsby-plugin-transition-link"
import { useIsJsEnabled } from "gatsby-plugin-js-fallback"
import {
  toggleInitialSiteLoad,
  toggleSiteLoaded,
} from "../state/initialSiteLoad"

import Header from "./SiteHeader"
import StickyHeader from "./StickyHeader"
import Footer from "./Footer"
import SiteOverlay from "./SiteOverlay"
import Cursor from "./Cursor"
import CookiesBanner from "./CookiesBanner"
import "../scss/all.scss"
import SiteHeader from "./SiteHeader"

const SiteLayout = props => {
  const isJsEnabled = useIsJsEnabled()

  // Setup our props
  const {
    dispatch,
    children,
    background,
    overlayColour,
    overlayActive,
    location,
    navActive,
    initialSiteLoad,
  } = props

  useEffect(() => {
    if (document.body.classList.contains("initial-site-render")) {
      dispatch(toggleInitialSiteLoad(false))

      setTimeout(() => {
        dispatch(toggleSiteLoaded(true))
      }, 1000)
    }
  }, [])

  return (
    <TransitionState>
      {({ transitionStatus }) => {
        // Disable scrollbar if these are true
        const noScroll =
          transitionStatus === "exiting" ||
          transitionStatus === "exited" ||
          navActive
            ? true
            : false
        return (
          <div>
            <Helmet
              bodyAttributes={{
                class: `body--${background} ${
                  noScroll && isJsEnabled ? `body--no-scroll` : ``
                }`,
              }}
            >
              <link
                rel="stylesheet"
                href="https://use.typekit.net/cnh3ppk.css"
              />
            </Helmet>

            <Header location={location} transitionStatus={transitionStatus} />

            <div className={`site-wrapper site-wrapper--${transitionStatus}`}>
              {children}
              <Footer />
            </div>

            <CookiesBanner />
            <StickyHeader transitionStatus={transitionStatus} />

            <TransitionPortal>
              <Cursor />
              <SiteOverlay
                overlayColour={overlayColour}
                overlayActive={overlayActive}
              />
            </TransitionPortal>
          </div>
        )
      }}
    </TransitionState>
  )
}

SiteLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(
  state => ({
    navActive: state.navActive.navActive,
    background: state.background.background,
    overlayColour: state.overlayColour.overlayColour,
    overlayActive: state.overlayActive.overlayActive,
  }),
  null
)(SiteLayout)
