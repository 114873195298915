import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { gsap } from "gsap";
import { toggleCursorPos, toggleCursorStyle } from "../../state/cursor"

const Cursor = props => {
  const cursorEl = useRef(null)
  const { dispatch, cursorPos, cursorStyle, navActive } = props

  useEffect(() => {
    // Use our state make sure the el is in same position
    // it was on last page (otherwise it jumps to 0,0)
    gsap.to(cursorEl.current, {
      x: cursorPos.x,
      y: cursorPos.y,
      opacity: 0.85,
      duration: 0
    })

    // Init positioning
    cursorPositioning(dispatch)

    return () => {
      // Cleanup remove cursor classes to reset for new page
      dispatch(toggleCursorStyle(false))
    }
  }, [])

  const cursorPositioning = (dispatch) => {
    // Detect mouse position and move our cursor in tandem
    window.addEventListener("mousemove", moveCursor)

    // Get all a tags and buttons and set cursorStyle state
    // to hover when mouse over each el
    const aTags = document.getElementsByTagName("a")
    const aTagsArr = Array.from(aTags)
    const buttons = document.getElementsByTagName("button")
    const buttonsArr = Array.from(buttons)

    // Combine a and button els into single array
    const linksArr = aTagsArr.concat(buttonsArr)

    // Loop and apply our eventListeners
    linksArr.map(link => {
      const cursorClasses =
        link.dataset.cursor !== undefined && link.dataset.cursor === "alt"
          ? `hover hover-alt`
          : `hover`
      link.addEventListener("mouseover", () => {
        dispatch(toggleCursorStyle(cursorClasses))
      }
      )
      link.addEventListener("mouseout", () =>
        dispatch(toggleCursorStyle(false))
      )
    })
  }  

  const moveCursor = e => {
    if(cursorEl.current && e) {
      gsap.to(cursorEl.current, {
        x: e.clientX,
        y: e.clientY,
        duration: 0.4
      })
  
      // Keep state up to date so we have the last on next page load
      dispatch(toggleCursorPos({ x: e.clientX, y: e.clientY }))
    }
  }

  return (
    <>
      <div ref={cursorEl} className={`cursor 
        ${cursorStyle !== false ? cursorStyle : ``} 
        ${navActive ? `alt` : ``}`}>
      </div>
    </>
  )
}

export default connect(
  state => ({
    cursorPos: state.cursorPos.cursorPos,
    cursorStyle: state.cursorStyle.cursorStyle,
    navActive: state.navActive.navActive,
  }),
  null
)(Cursor)
