import React, { useEffect, useRef } from "react"
import { gsap } from "gsap"

const SiteOverlay = props => {
  const overlay = useRef(null)

  useEffect(() => {
    overlayTransition(props.overlayActive, overlay.current)
  }, [props.overlayActive])

  return (
    <div
      ref={overlay}
      className={
        props.overlayColour
          ? `site-overlay site-overlay--${props.overlayColour}`
          : `site-overlay`
      }
    ></div>
  )
}

const overlayTransition = (active, el) => {
  // Transition out the current page
  // with overlay swipe animation
  const ease = `power2.out` // ease type of anim
  const tl = gsap.timeline()

  if (active) {
    tl.to(el, {
      opacity: 1,
      duration: 0,
    }).to(el, {
      duration: 1,
      ease,
      y: `0vh`, // Slide in - cover whole screen with overlay
    })
  } else {
    // slide out invisible
    tl.to(el, {
      opacity: 0,
      duration: 0.5,
      delay: 0.5,
    }).to(el, {
      duration: 0.25,
      delay: 0.25,
      y: `100vh`, // Revert off screen,
    })
  }
}

export default SiteOverlay
