import React, { useEffect, useRef, useState } from "react"
import PageLink from "../PageLink"
import Chevron from "../Symbols/chevron"

const CookiesBanner = () => {
  const cookiesBanner = useRef(null)
  const [cookie, setCookie] = useState(null)
  const [cookiesEl, toggleCookiesEl] = useState(null)

  useEffect(() => {
    const cookieExists = readCookie("broadgateCookiesBanner")
    if (cookieExists && cookieExists === "seen-cookie-message") {
      setCookie(true)
    }
  }, [])

  const handleCookies = e => {
    e.preventDefault()
    createCookie("broadgateCookiesBanner", "seen-cookie-message", 90, "/")

    // Update hook to hide banner
    toggleCookiesEl(true)
  }

  return (
    <div
      ref={cookiesBanner}
      className={`cookies-banner ${cookiesEl ? `inactive` : ``}`}
      style={{
        display: cookie ? `none` : `block`,
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-bp1-12">
            <div className="cookies-banner__inner">
              <p className="cookie-banner__text">This website uses Cookies</p>
              <div className="cookies-banner__buttons">
                <a
                  className="button button--dark"
                  onClick={e => handleCookies(e)}
                  data-cursor="alt"
                >
                  <span>Accept &amp; Close</span> <Chevron />
                </a>
                <PageLink
                  className="button"
                  url="/privacy"
                  cursor="alt"
                  colour="black"
                  menuLink={false}
                >
                  <span>More Information</span> <Chevron />
                </PageLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const createCookie = (name, value, days, path) => {
  if (days) {
    var date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    var expires = "; expires=" + date.toGMTString()
  } else var expires = ""
  document.cookie = name + "=" + value + expires + "; path=" + path
}

const readCookie = name => {
  var nameEQ = name + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == " ") c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export default CookiesBanner
