import React, { useEffect, useRef } from "react"
import { connect } from "react-redux"
import { Waypoint } from "react-waypoint"

import PageLink from "../PageLink"
import SiteMenuTrigger from "../SiteMenu/SiteMenuTrigger"
import Breadcrumbs from "../Breadcrumbs"

import { toggleStickyHeader } from "../../state/header"
import { toggleLogoHasSymbol } from "../../state/logo"

const StickyHeader = props => {
  const stickyHeaderEl = useRef(null)
 
  const {
    dispatch,
    navActive,
    stickyHeader,
    stickyHeaderColour,
    transitionStatus
  } = props

  return (
    <>
      <Waypoint
        onEnter={(waypointProps) => {
          if(props.transitionStatus === 'entered') {
            if(waypointProps.currentPosition === 'inside') {
              animateHeaderDown(dispatch)
            }
          }          
        }}
        onLeave={(waypointProps) => {
          if(props.transitionStatus === 'entered') {
            if(waypointProps.currentPosition === 'below') {
              animateHeaderUp(dispatch)
            }
          }
        }}
        scrollableAncestor={typeof window !== "undefined" ? window : null}
      >
        <div className="faux-header"></div>
      </Waypoint>

      <section 
        aria-hidden={true} 
        ref={stickyHeaderEl} 
        className={`
          sticky-header
          ${navActive ? ` active` : ``}
          ${stickyHeaderColour ? ` sticky-header--${stickyHeaderColour}` : ``}
        `}
        style={{
          transform: stickyHeader ? `translateY(0)` : `translateY(-200%)`,
          transitionDelay: transitionStatus === 'entered' ? `0s` : `.5s`,
        }}
        >
        <PageLink
          className="sticky-header__logo"
          url={`/`}
          dispatch={dispatch}
          colour={`black`}
          menuLink={false}
          tabindex="-1"
        >
          <span>Broadgate</span>
        </PageLink>
        <div className="sticky-header__tools">
          <Breadcrumbs />
          <SiteMenuTrigger stickyHeader={true} />
        </div>
      </section>
    </>
  )
}

const animateHeaderDown = (dispatch) => {
  dispatch(toggleStickyHeader(true)) // Show Sticky Header

  // Remove the Logo Symbol
  dispatch(toggleLogoHasSymbol(false))  
}

const animateHeaderUp = (dispatch) => {
  dispatch(toggleStickyHeader(false)) // Hide Sticky Header

  // Reset logo with state change
  dispatch(toggleLogoHasSymbol(true))
}  

export default connect(
  state => ({
    stickyHeader: state.stickyHeader.stickyHeader,
    stickyHeaderColour: state.stickyHeaderColour.stickyHeaderColour
  }),
  null
)(StickyHeader)
